import * as React from 'react';
import { FC } from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import PostList from '../components/PostList';
import Pagination from '../components/Pagination';

interface Props extends FC {
  data: { allWordpressPost: { edges: [] } };
  pageContext: {
    name: string;
    previousPagePath: string;
    nextPagePath: string;
    numPages: number;
  };
};

const Category = ({ data, pageContext }: Props) => {

  const { edges: posts } = data.allWordpressPost;
  const { slug, name } = pageContext;

  return (
    <Layout>
      <Helmet title={`${name}`} />
      <PostList posts={posts} />
      <Pagination pageContext={pageContext} pathPrefix={`/categories/${slug}`} />
    </Layout>
  );
};

export default Category;

export const pageQuery = graphql`
  query CategoryPageAndCategoryPage($slug: String!, $limit: Int!, $skip: Int!) {
    allWordpressPost(
      filter: { categories: { elemMatch: { slug: { eq: $slug } } } }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          ...PostListFields
        }
      }
    }
  }
`;
